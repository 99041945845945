import React, { Component } from "react";
import {withRouter} from "next/router";
import Head from 'next/head';
import SlackWebHook from '../services/Slack';
import PropTypes from "prop-types";
import Navbar from "../components/Shared/Navbar";
export const config = { amp: true };

class SiteLayout extends Component {

  componentDidCatch(error, info) {
    SlackWebHook.sendSlackMessage('SiteLayout','componentDidCatch',`${error.message}-${info}`);
  }

  render() {
    return (
      <div>
        <Head>
          <title>{process.env.NEXT_PUBLIC_BRAND_NAME || 'Collegify'} TestPrep</title>
        </Head>
        <main className="overflow-x-hidden">
          <Navbar />
          {this.props.children}
        </main>
      </div>
    )
  }
}

const propTypes = {
  /** Children element */
  children: PropTypes.node,
};

const defaultProps = {
};

SiteLayout.propTypes = propTypes;
SiteLayout.defaultProps = defaultProps;

export default withRouter(SiteLayout);