import React, { Component } from 'react';
import PropTypes from 'prop-types'
import SiteLayout from "../layouts/SiteLayout";
import SiteLoader from "../components/SiteLoader";
import LoginForm from "../components/Auth/LoginForm";
import CookiesAction from "../services/Cookie";
import { withRouter } from 'next/router';

class Login extends Component {

  static getInitialProps({ query }) {
    return { query };
  }

  state = {
    pageLoading: true
  }

  componentDidMount() {
    if(CookiesAction.getCookie('_token') !== ''
      && CookiesAction.getCookie('_token') !== null
      && CookiesAction.getCookie('_token') !== undefined
    ){
      this.setState({pageLoading: true}, () => {
        this.goToNextPage();
      });
    }else {
      if (process.env.NEXT_PUBLIC_LOGIN_ENABLE === undefined || process.env.NEXT_PUBLIC_LOGIN_ENABLE === "true") {
        this.setState({pageLoading: false});
      }else {
        location.replace(process.env.NEXT_PUBLIC_REDIRECTION_URL + location.search + (location.search.trim() === '' ? '?' : '&') + 'platform=' + btoa(location.host));
      }
    }
  }

  goToNextPage = () => {
    if(this.props.query.next && this.props.query.next !== ""){
        this.props.router.push(`${atob(this.props.query.next)}`);
    }else {
        this.props.router.push(`/dashboard`);
    }
  }

  render() {
    return (
      <SiteLayout>
        {
          this.state.pageLoading &&
          <SiteLoader />
        }
        <div className="login-wrapper d-flex justify-content-between align-items-start">
          <section className="login-default--wrapper active">
            <div className="signin-as-text txt-54--jumbo color-secondary">
              Welcome back!
            </div>
            <h5 className="sample-line">Sign into your Testprep account.</h5>
            <LoginForm next={this.goToNextPage}/>
          </section>
          <div className="img-left--wrapper">
            <picture className="desktop-only">
              <source type="image/webp" srcSet="/images/bg_landing.webp" />
              <source type="image/svg" srcSet="/images/bg_landing.svg" />
              <img src="/images/bg_landing.svg" className="desktop-only" alt="LoginImage" />
            </picture>
          </div>
        </div>
      </SiteLayout>
    )
  }
}

const propTypes = {
  router: PropTypes.object,
};

Login.propTypes = propTypes;

export default withRouter(Login);
