import gql from 'graphql-tag';
import Client from "../services/Client";


const AuthAction = {

  // Login response with token
  // @ author: Chandan Kumar Gupta
  // @ params: email: string, password: string, loginType: string
  loginUser(email, password, loginType = 'frontend', isTestprep = 1) {
    const LOGIN_USER = gql`
                mutation loginUser($input: LoginInput!) {
                    loginUser(input: $input) {
                        code
                        status
                        message
                        data {
                          userId
                          accessToken
                          firstName
                          lastName
                          email
                          mobile
                          userStatus
                          countryId
                          orgId
                          roleDetails
                        }
                    }
                }
            `;
    return Client.mutate({
      mutation: LOGIN_USER,
      variables: {
        input: {
          email: email,
          password: password,
          loginType: loginType,
          isTestprep: isTestprep
        }
      }
    });
  },

  /**
     * Login response with token
     * @author: Arijit Deb Roy
     * @params: email: <string>, userId: <Int> 
     */
  loginWithToken(email, userId, loginType = 'frontend', isTestprep = 1) {
    const LOGIN_USER_WITHOUT_PASS = gql`
            mutation loginWithToken($input: LoginWithoutPassInput!) {
              loginWithToken(input: $input) {
                    code
                    status
                    message
                    data {
                      userId
                      accessToken
                      firstName
                      lastName
                      email
                      mobile
                      userStatus
                      countryId
                      orgId
                      roleDetails
                    }
                }
            }
        `;
    return Client.mutate({
      mutation: LOGIN_USER_WITHOUT_PASS,
      variables: {
        input: {
          email: email,
          userId: userId,
          loginType: loginType,
          isTestprep: isTestprep
        }
      }
    });
  },
  
  registerUser(firstName, lastName, email, password, mobile, countryId, gender, referralCode) {
    const REGISTER_USER = gql`
                mutation RegisterStudent($input: StudentRegistrationInput!){
                  RegisterStudent(input: $input){
                    code
                    status
                    message
                    data{
                      user{
                        userId
                        token
                      }
                    }
                  }
                }
            `;
    return Client.mutate({
      mutation: REGISTER_USER,
      variables: {
        input: {
          firstName: firstName,
          lastName: lastName,
          email: email,
          countryId: countryId,
          mobile: mobile,
          password: password,
          roleMachineName: 'users',
          gender: gender,
          disabilityStatus: 0,
          userDisability: null,
          referralCode: referralCode,
          courseId: null,
          courseExamDate: null,
          currentGrade: null,
          schoolBoard: null,
          schoolName: null
        },
      }
    });
  },

  listCountry(status = 'active') {
    const LIST_COUNTRY = gql`
                query listCountry($token: AccessToken!, $input: CountryListInput!){
                  listCountry(token: $token, input: $input){
                    code
                    status
                    message
                    data{
                      totalCount
                      countries{
                        countryId
                        name
                        dialCode
                        code
                        currencyId
                        otherJson
                        status
                        createdBy
                        updatedBy
                        createdAt
                        updatedAt
                      }
                    }
                  }
                }
            `;
    return Client.query({
      query: LIST_COUNTRY,
      variables: {
        input: {
          status: status,
        },
        token: {
          accessToken: null,
          accessRole: null
        }
      }
    });
  },
  /**
   * Forget Password token response with user email
   * @author: Susanta Chakraborty
   * @params: email: <string>, loginType: <string>
   */
  forgetPasswordUser(email) {
    const PASSWORD_FORGET_USER = gql`
      mutation forgetPasswordUser($input: UserPasswordForgetInput!){
          forgetPasswordUser(input: $input){
          code
          status
          message
          data{
            totalCount
            user{
            userId
            token
            }
          }
        }
      }
    `;
    return Client.mutate({
      mutation: PASSWORD_FORGET_USER,
      variables: {
        input: {
          email: email,
        }
      }
    });
  },

  /**
   * Reset Password response with user token and new pass
   * @author: Susanta Chakraborty
   * @params: token: <string>, password: <string>
   */
  resetPasswordUser(token, password) {
    const PASSWORD_RESET_USER = gql`
      mutation resetPasswordUser($input: UserPasswordResetInput!){
        resetPasswordUser(input: $input){
          code
          status
          message
          data{
            totalCount
            user{
              userId
            }
          }
        }
      }
    `;
    return Client.mutate({
      mutation: PASSWORD_RESET_USER,
      variables: {
        input: {
          token: token,
          password: password,
        }
      }
    });
  }
}

export default AuthAction;
