import React from 'react';
import PropTypes from "prop-types";
const propTypes = {
  text: PropTypes.string
};

SiteLoader.propTypes = propTypes;

export default function SiteLoader({text}) {
  return (
    <div className="full-screen">
      {/* <div className="loader">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div> */}
      <div className="loader-inner">
        <div className="book">
            <div className="back"></div>
            <div className="page5"></div>
            <div className="page4"></div>
            <div className="page3"></div>
            <div className="page2"></div>
            <div className="page1"></div>
            <div className="front"></div>
        </div>
      </div>
      {
        text && text != "" && 
        <div className="loader-text">
          <div className="txt-18--body_subtitle bold mb-4">{text}</div>
          <div className="txt-16--body bold color-base text-center">Please Wait.....</div>
        </div>
      }
    </div>
  )
}
