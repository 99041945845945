import React, { Component } from 'react';
import { MdArrowForward } from '@react-icons/all-files/md/MdArrowForward';
import { FaSpinner } from '@react-icons/all-files/fa/FaSpinner';
import intersectionBy from "lodash/intersectionBy";
import Validator from "../../services/Validation";
import AuthAction from "../../actions/AuthAction";
import CookiesAction from "../../services/Cookie";
import { withRouter } from 'next/router';
import { error } from "../Toast";
import SlackWebHook from "../../services/Slack";
import AuthCheck from "../../services/AuthCheck";
import PropTypes from 'prop-types';

const roles = [{roleMachineName: 'users'}];

class LoginForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      validate: false,
      onLoad:false,
    }
  }

  componentDidMount() {
    try{
      this.setState({
        ...this.state,
        email: this.props.router.query.email ? atob(this.props.router.query.email) : ''
      })
    } catch(err) {
      error("Invalid URL!!!");
    }
  }

  onChange = (e) => {
    const parentThis = this;
    const state = parentThis.state;
    state[e.target.name] = e.target.value;
    state.validate = false;
    state.onLoad = false;
    this.setState(state);
  }

  gotoForgetPage = () => {
    const { email } = this.state;
    if(email !== '' && email !== undefined && email !== null){
      this.props.router.push('/forget-pass?email=' + email);
    }else{
      this.props.router.push('/forget-pass');
    }
  }

  login = () =>{
    const parentThis = this;
    const state = parentThis.state;
    const {email, password} = state;
    parentThis.setState({validate:true});
    if(Validator.email(email.toLowerCase()) && Validator.text(password) && password.length >5) {
      parentThis.setState({onLoad:true});
      AuthAction.loginUser(email.toLowerCase(), password).then(function (res) {
        parentThis.setState({onLoad:false});
        const response = res.data.loginUser;
        if (response.status === 'success' && response.code === '200') {
          const data = response.data;
          CookiesAction.setCookie('_token', data.accessToken, 7);
          let role = AuthCheck.checkAuth();
          let roleDetails = intersectionBy(Object.values(JSON.parse(role.roleDetails)), roles, 'roleMachineName');
          if (roleDetails.length === 1 && (process.env.NEXT_PUBLIC_ORGANIZATION_ID === undefined || process.env.NEXT_PUBLIC_ORGANIZATION_ID === "false" || parseInt(process.env.NEXT_PUBLIC_ORGANIZATION_ID) === parseInt(role.orgId))) {
            if(parentThis.props.next !== undefined){
              parentThis.props.next();
            }else {
              parentThis.props.router.push('/dashboard');
            }
          } else {
            CookiesAction.deleteCookie('_token');
            error("You don't have access to this platform");
          }
        } else {
          error(response.message);
        }
      }).catch(function (err) {
        SlackWebHook.sendSlackMessage('LoginForm', 'login', `${err.message}`);
        parentThis.setState({onLoad:false});
        error('Something went wrong');
      });
    }
    else{
      error('Please fill the mandatory fields');
    }
  }

  render() {
    const {email, password, validate, onLoad} = this.state;
    const { page } = this.props;

    return (
      <form className="form-login--wrapper" method="post" onSubmit={(e)=>{e.preventDefault()}}>
        <div className="form-group mb-4">
          <label htmlFor="login-email" className="col-form-label">Email</label>
          <input
            type="email"
            onChange={this.onChange}
            placeholder="Enter your registered email"
            className={`form-control ${validate && !Validator.email(email.toLowerCase()) && 'error'}`}
            name="email"
            value={email}
          />
        </div>
        <div className="form-group mb-4">
          <label htmlFor="login-password" className="col-form-label">Password</label>
          <input
            type="password"
            onChange={this.onChange}
            placeholder="Password"
            className={`form-control password ${validate && (!Validator.text(password) || password.length<6) && 'error'}`}
            name="password"
            value={password}
          />
        </div>
        <a className='forgot-pass pointer' onClick={this.gotoForgetPage}>Forgot Password ?</a>
        <div className={` ${page==="checkout" ?'btn-wrapper d-flex justify-content-end': 'btn-wrapper'}`}>
          {
            page === "checkout" ?
              <button className="btn btn-link p-0"
                onClick={this.login}
                disabled={onLoad}
              >
              CONTINUE TO CHECKOUT
                {
                  onLoad?
                    <FaSpinner icon="spinner" fontSize="18px" className="ms-2 spinner" />
                    :
                    <MdArrowForward fontSize="18px" className="ms-2" />
                }
              </button>
              :
              <button
                className="btn-solid-normal --primary w-100"
                onClick={this.login}
                disabled={onLoad}
              >
            CONTINUE TO TESTPREP
                {
                  onLoad?
                    <FaSpinner icon="spinner" fontSize="20px" className="ms-3 spinner" />
                    :
                    <MdArrowForward fontSize="20px" className="ms-3" />
                }
              </button>
          }
        </div>
      </form>
    )
  }
}

LoginForm.propTypes = {
  page: PropTypes.string,
  router: PropTypes.object,
  next: PropTypes.func
};

export default withRouter(LoginForm);